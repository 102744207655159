<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-19 15:44:49
 * @LastEditTime: 2022-10-20 11:57:44
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\robotWarehouse.vue
-->
<template>
  <div class="robotWarehouse">
    <div class="header">
      <span class="tips">状态</span>
      <el-select v-model="listStatus" placeholder="请选择" size="medium">
        <el-option label="全部" value="0"></el-option>
        <el-option label="已开通" value="1"></el-option>
        <el-option label="未开通" value="2"></el-option>
      </el-select>
    </div>
    <pagination2
      :option="{ status: listStatus }"
      url="/liveRobot/robotLibrary"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="机器人库名称" prop="name"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              <span style="color:rgba(66, 194, 68, 1)" v-if="row.status == 1">
                已开通
              </span>
              <span style="color:#FF3535" v-else>未开通</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <span class="disable" v-if="row.type == 1">查看</span>
              <el-button
                type="text"
                @click="detail(row)"
                v-else-if="row.status == 1"
              >
                查看
              </el-button>

              <el-button type="text" v-else @click="openStatus = true">
                开通
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 开通 -->
    <openingfunction
      v-if="openStatus"
      :dialogstatus.sync="openStatus"
    ></openingfunction>

    <!-- 查看机器人库 -->
    <robot-detail
      v-if="detailStatus"
      :dialogstatus.sync="detailStatus"
      :detailType="detailType"
    ></robot-detail>
  </div>
</template>

<script>
import robotDetail from './robotDetail'
import openingfunction from '@/components/copyrightprotect/openingfunction'
export default {
  components: {
    openingfunction,
    robotDetail,
  },

  data() {
    return {
      detailType: '',
      detailStatus: false,
      openStatus: false,
      listStatus: '0',
    }
  },

  methods: {
    async detail(row) {
      this.detailType = row.type
      this.$nextTick(() => {
        this.detailStatus = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.robotWarehouse {
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .disable {
    font-size: 14px;
    color: #cacaca;
    cursor: no-drop;
  }
  .tips {
    flex-shrink: 0;
    font-size: 14px;
    color: #333333;
    margin-right: 10px;
  }
}
</style>
