<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-18 14:21:58
 * @LastEditTime: 2022-10-19 15:53:45
 * @LastEditors: cyy
 * @Description: 智能机器人
 * @FilePath: \teacherdingding.com\src\views\liveRobot\liveRobot.vue
-->
<template>
  <div class="liveRobot">
    <el-tabs v-model="activeName">
      <el-tab-pane label="机器人" name="1">
        <all-data v-if="activeName == 1"></all-data>
      </el-tab-pane>
      <el-tab-pane label="分组" name="2">
        <groups v-if="activeName == 2"></groups>
      </el-tab-pane>
      <el-tab-pane label="机器人库" name="3">
        <robot-warehouse v-if="activeName == 3"></robot-warehouse>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import robotWarehouse from './components/robotWarehouse'
import allData from './components/allData'
import groups from './components/groups'
import bg from '@/mixin/background'
export default {
  mixins: [bg],

  components: {
    allData,
    groups,
    robotWarehouse,
  },

  data() {
    return {
      activeName: '1',
    }
  },
}
</script>

<style lang="scss" scoped>
.liveRobot {
  margin: 20px;
  background-color: #fff;
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
  ::v-deep .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  ::v-deep .el-tabs__content {
    padding: 5px 20px 20px;
  }
}
</style>
