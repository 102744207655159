<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-20 11:34:03
 * @LastEditTime: 2022-10-20 11:55:05
 * @LastEditors: cyy
 * @Description:查看机器人库
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\robotDetail.vue
-->
<template>
  <div class="robotDetail">
    <el-dialog
      title="查看"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <pagination2
          :option="{ type: detailType }"
          url="/liveRobot/sysRobotList"
          ref="childDialogBox"
          class="pagination2"
        >
          <template v-slot:default="{ tableData }">
            <el-table
              :height="600"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column label="机器人ID" prop="id"></el-table-column>
              <el-table-column label="机器人">
                <template slot-scope="{ row }">
                  <div class=" robot_contain">
                    <div class="tu">
                      <img :src="row.photo" alt="" />
                    </div>
                    <div class="name hidetext">{{ row.name }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   post: {
      //     type: this.detailType,
      //   },
    }
  },

  props: {
    detailType: {
      type: String,
      default: undefined,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  //   created() {
  //     console.log(this.detailType)
  //     this.post.type = this.detailType
  //   },

  methods: {
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.robotDetail {
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
  }
  .dialogVisible_bottom {
  }
  .pagination2 {
    .robot_contain {
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
  }
}
</style>
