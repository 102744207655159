<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-18 17:27:22
 * @LastEditTime: 2022-11-03 10:23:47
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\groups.vue
-->
<template>
  <div class="groups">
    <!-- 顶部搜索 -->
    <div class="header mb10">
      <el-button type="primary" size="medium" @click="addGrouping">
        添加分组
      </el-button>

      <div>
        <el-input
          clearable
          @keyup.enter.native="toSearch"
          v-model="search"
          size="medium"
          style="width:188px"
          placeholder="输入昵称搜索"
        ></el-input>
        <el-button
          type="primary"
          class="ml20"
          style="width:74px"
          @click="toSearch"
          size="medium"
        >
          搜索
        </el-button>
      </div>
    </div>
    <!-- 表格 -->
    <pagination2
      ref="child"
      :option="formData"
      url="/pcUser/getGroupList"
      @complete="complete"
      class="pagination2"
    >
      <template>
        <el-table
          @sort-change="sortChange1"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          :data="completeData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column type="selection" width="45px"></el-table-column>
          <el-table-column prop="gname" label="分组名称" width="180px" />
          <el-table-column label="机器人数量" prop="number"></el-table-column>
          <el-table-column label="创建人" prop="cname">
            <template slot-scope="{ row }">
              {{ row.cname || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" sortable="custom">
            <template slot-scope="{ row }">
              {{ row.create_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="reName(scope.row)">
                重命名
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="text" @click="goDetail(scope.row)">
                查看
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                @click="del([scope.row.gid], 1)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <el-checkbox
            :indeterminate="isIndeterminate"
            @change="checkALL"
            v-model="overdue"
            :true-label="1"
            :false-label="2"
            class="ml10"
          >
            <div class="tips ml10">已选择{{ selectList.length }}条</div>
          </el-checkbox>

          <el-button size="medium" @click="del(0, 2)" style="width:130px">
            批量删除
          </el-button>
        </div>
      </template>
    </pagination2>

    <!-- 添加分组 -->
    <el-dialog
      title="添加分组"
      :visible.sync="addGroup"
      width="422px"
      v-if="addGroup"
      :close-on-click-modal="false"
    >
      <div class="flex-center">
        <span class="tips mr20">名称</span>
        <el-input
          ref="inputEl"
          type="text"
          placeholder="输入分组名称"
          v-model="groupList.name"
          size="medium"
          maxlength="10"
          show-word-limit
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddMenuCancel" size="medium">取 消</el-button>
        <el-button type="primary" @click="AddMenuFix" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看 -->
    <el-drawer
      title="查看"
      class="el_drawer"
      size="800px"
      :visible.sync="drawer"
    >
      <div slot="title" class="title">查看</div>
      <div class="top">
        <span class="tips mr10">关键词</span>
        <el-input
          clearable
          style="width: 338px"
          @keyup.enter.native="toSearch2"
          size="medium"
          v-model="search2"
          class="input-with-select  "
          :placeholder="
            formData2.search_type == 2 || formData2.search_type == ''
              ? '输入机器人名称搜索'
              : '输入机器人ID搜索'
          "
        >
          <el-select
            size="medium"
            v-model="formData2.search_type"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="机器人名称" value="2"></el-option>
            <el-option label="机器人ID" value="1"></el-option>
          </el-select>
        </el-input>
        <el-button
          type="primary"
          class="ml20"
          @click="toSearch2"
          style="width:74px"
          size="medium"
        >
          搜索
        </el-button>
      </div>
      <!-- 表格 -->
      <pagination2
        ref="child2"
        :option="formData2"
        url="/liveRobot/robotList"
        @complete="complete2"
        :lodding="false"
        class="pagination2"
      >
        <template>
          <el-table
            @sort-change="sortChange"
            ref="multipleTable2"
            :data="completeData2"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            @selection-change="handleSelectionChange2"
          >
            <el-table-column type="selection" width="45px"></el-table-column>
            <el-table-column prop="robot_id" label="机器人ID" width="100px" />
            <el-table-column label="机器人">
              <template slot-scope="{ row }">
                <div class=" robot_contain">
                  <div class="tu">
                    <img :src="row.photo" alt="" />
                  </div>
                  <div class="name hidetext">
                    {{ row.name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创建人" prop="uname">
              <template slot-scope="{ row }">
                {{ row.uname || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间" sortable="custom" prop="c_time">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="removeRobot(1, [scope.row.robot_id])"
                >
                  移出分组
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <div class="footer">
            <el-checkbox
              v-model="overdue2"
              :indeterminate="isIndeterminate2"
              :true-label="1"
              :false-label="2"
              class="ml10"
              @change="checkALL2"
            >
              <div class="tips ml10">已选择{{ selectList2.length }}条</div>
            </el-checkbox>

            <el-button
              size="medium"
              style="width:130px"
              @click="
                removeRobot(
                  2,
                  selectList2.map(item => item.robot_id)
                )
              "
            >
              批量移出
            </el-button>
          </div>
        </template>
      </pagination2>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,

      isIndeterminate: false,

      isIndeterminate2: false,

      completeData: [],

      completeData2: [],

      overdue: 2,

      overdue2: 2,

      selectList: [],

      selectList2: [],

      isedit: false,

      search: '',

      search2: '',

      formData2: {
        search_type: '2',
        search: '',
        gid: '',
        sort: '',
        is_page: 1,
        orderType: 2,
      },

      formData: {
        search: '',
        type: '1',
        user_type: '2',
        sortType: '',
        orderType: 2,
      },

      groupList: {
        name: '',
        user_type: 2,
        gid: '',
      },

      addGroup: false,
    }
  },

  methods: {
    // 移出分组
    async removeRobot(type, val) {
      if (type == 2 && this.selectList2.length == 0) {
        this.$root.prompt('请先选择机器人')
        return
      }
      this.$confirm(' 确定移出已选的机器人？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveRobot/setRobotGroup',
            data: { robot_ids: val, gid: 0 },
            successMsg: true,
          })
          this.$refs.child2.reset()
        })
        .catch(() => {})
    },

    sortChange1(val) {
      this.formData.sortType = !val.order
        ? ''
        : val.order === 'ascending'
        ? '2'
        : '1'
    },

    sortChange(val) {
      this.formData2.sort = !val.order
        ? ''
        : val.order === 'ascending'
        ? '1'
        : '2'
    },
    goDetail(val) {
      this.formData2.gid = val.gid
      this.$nextTick(() => {
        this.drawer = true
      })
    },

    toSearch2() {
      this.formData2 = _.assign({}, this.formData2, { search: this.search2 })
    },

    toSearch() {
      this.formData = _.assign({}, this.formData, { search: this.search })
    },

    // 全选的处理
    complete({ data }) {
      this.completeData = data
    },

    checkALL(val) {
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.selectList = val
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.completeData.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.completeData.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },

    // 机器人全选的处理
    complete2({ data }) {
      this.completeData2 = data
    },

    checkALL2(val) {
      if (val == 1) {
        this.$refs.multipleTable2.toggleAllSelection()
      } else {
        this.$refs.multipleTable2.clearSelection()
      }
    },

    handleSelectionChange2(val) {
      this.selectList2 = val
      this.isIndeterminate2 =
        this.selectList2.length > 0 &&
        this.completeData2.length != this.selectList2.length
      if (
        this.selectList2.length > 0 &&
        this.completeData2.length == this.selectList2.length
      ) {
        this.overdue2 = 1
      } else {
        this.overdue2 = 2
      }
      this.$forceUpdate()
    },

    async del(val, type) {
      let id =
        (this.selectList.length > 0 && this.selectList.map(item => item.gid)) ||
        []
      if (type == 2 && id.length == 0) {
        this.$root.prompt('请先选择分组')
        return
      }
      const gids = type == 1 ? val : id
      this.$confirm('确定删除已选的分组？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveRobot/delGroup',
            data: { gids },
            successMsg: true,
          })
          this.$refs.child.reset()
        })
        .catch(() => {})
    },

    reName(val) {
      this.isedit = true

      this.groupList = _.assign({}, this.groupList, {
        name: val.gname,
        gid: val.gid,
      })
      this.addGrouping()
    },

    addGrouping() {
      this.addGroup = true
      this.$nextTick(() => {
        this.$refs.inputEl.focus()
      })
    },

    async AddMenuFix() {
      if (!this.groupList.name) {
        this.$root.prompt('请输入分组名称')
        return
      }
      if (this.isedit) {
        await this.$http({
          url: '/pcUser/editGroup',
          data: this.groupList,
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/pcUser/addGroup',
          data: this.groupList,
          successMsg: true,
        })
      }
      this.$refs.child.reset()
      this.AddMenuCancel()
    },

    AddMenuCancel() {
      this.groupList.name = ''
      this.isedit = false
      this.addGroup = false
    },
  },
}
</script>

<style lang="scss" scoped>
.groups {
  img {
    width: 100%;
    width: 100%;
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
      .el-input-group__prepend {
        background-color: #fff;
        width: 80px;
      }
    }
  }

  ::v-deep .el_drawer {
    .robot_contain {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
    .el-drawer__header {
      margin-bottom: 0;
    }
    .pagination2 {
      @extend %scroll;
      flex: 1;
      overflow: auto;
    }
    .el-drawer__body {
      padding: 20px;
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 18px;
      color: #333333;
    }
  }
  ::v-deep .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .tips {
    flex-shrink: 0;
    font-size: 14px;
    color: #333333;
    margin-right: 20px;
  }
  .footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}
</style>
